import { Link, useLocation } from "react-router-dom";
import { SidebarMenuList, SidebarMenuListItem, SidebarWrapper } from "./styles";

import { ReactComponent as OverviewIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as UsersIcon } from "../../assets/icons/avatar.svg";
import { ReactComponent as FormsIcon } from "../../assets/icons/bullet-form.svg";
import { ReactComponent as PracticeIcon } from "../../assets/icons/ui_settings.svg";

const Sidebar = ({ dashboardMode, setIsDashboardModeModalVisible }: any) => {
	const location = useLocation();
	const isActivePath = (path: string) => location.pathname.includes(path);

	const sidebarMenu = [
		{
			title: "Overview",
			route: "/overview",
			mode: ["integration", "connect"],
			icon: (
				<OverviewIcon
					fill={isActivePath("/overview") ? "#0f9af0" : "#002e46"}
				/>
			),
		},
		{
			title: "Users",
			route: "/users",
			mode: ["integration", "connect"],
			icon: <UsersIcon fill={isActivePath("/users") ? "#0f9af0" : "#002e46"} />,
		},
		{
			title: "Consult Forms",
			route: "/forms",
			mode: ["integration"],
			icon: <FormsIcon fill={isActivePath("/forms") ? "#0f9af0" : "#002e46"} />,
		},
		{
			title: "Manage Account",
			route: "/manage-account",
			mode: ["integration", "connect"],
			icon: (
				<PracticeIcon
					fill={isActivePath("/manage-account") ? "#0f9af0" : "#002e46"}
				/>
			),
		},
	];
	return (
		<SidebarWrapper>
			<SidebarMenuList>
				{sidebarMenu
					.filter(({ mode }) => mode.includes(dashboardMode))
					.map((item, index) => {
						const { title, route, icon } = item;
						const active = isActivePath(route);
						return (
							<SidebarMenuListItem active={active} key={index + title}>
								<Link to={route}>
									<span className="icon">{icon}</span>
									{title}
								</Link>
							</SidebarMenuListItem>
						);
					})}
			</SidebarMenuList>

			<span
				className="dashboard-mode-switch"
				onClick={() => setIsDashboardModeModalVisible(true)}
				onKeyUp={() => setIsDashboardModeModalVisible(true)}
			>
				<span style={{ color: "#333" }}>
					Dashboard mode:{" "}
					<span style={{ textTransform: "capitalize" }}>{dashboardMode}</span>
				</span>
				<p>
					<i className="lni lni-shuffle" />
					Switch
				</p>
			</span>
		</SidebarWrapper>
	);
};

export default Sidebar;
