import { Col, Row, Skeleton } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Button from "component/Button";
import Card from "component/Card";

// redux
import { consultSelector, fetchAllConsults } from "redux/reducers/consults";
import { fetchAllForms, formSelector } from "redux/reducers/forms";
import { fetchAllLabRequests } from "redux/reducers/labRequests";
import { fetchAllReviews } from "redux/reducers/reviews";
import { fetchBalance } from "redux/reducers/transactions";
import { fetchAllUsersCount, userSelector } from "redux/reducers/users";

import { ReactComponent as UserIcon } from "assets/icons/avatar.svg";
import { ReactComponent as FormsIcon } from "assets/icons/bullet-form.svg";
import { ReactComponent as ResponsesIcon } from "assets/icons/doctor.svg";

import EncounterTable from "../Encounters/containers/EncounterTable";
import LabRequestsTable from "../LabRequests/containers/LabRequestsTable";
import {
	ContentWrapper,
	DashboardWrapper,
	OverviewIntroCardWrapper,
	StatsCardWrapper,
} from "./styles";

const Dashboard = ({ dashboardMode }: any) => {
	const dispatch = useDispatch();

	const { usersCount, isUsersCountLoading } = useSelector(userSelector);
	const { consults, isConsultsLoading } = useSelector(consultSelector);
	const { forms, isFormsLoading } = useSelector(formSelector);

	useEffect(() => {
		dispatch(fetchBalance());
		dispatch(fetchAllUsersCount());
		dispatch(fetchAllConsults());
		dispatch(fetchAllForms());
	}, [dispatch]);

	const statsData1 = [
		{
			title: "Users",
			value: usersCount || 0,
			loading: isUsersCountLoading,
			actionText: "View users",
			icon: <UserIcon />,
			url: "/users",
			dashboardMode: ["integration", "connect"],
		},
		// TODO - implement responses count with associated API call once available
		{
			title: "Responses",
			value: consults?.length || 0,
			loading: isConsultsLoading,
			actionText: "View responses",
			icon: <ResponsesIcon />,
			url: "/encounters",
			dashboardMode: ["integration"],
		},
	];
	const statsData2 = [
		{
			title: "Consult forms",
			value: forms?.length || 0,
			loading: isFormsLoading,
			actionText: "View consult forms",
			icon: <FormsIcon />,
			url: "/forms",
			dashboardMode: ["integration"],
		},
		{
			title: "Published forms",
			value: forms?.length || 0,
			loading: isFormsLoading,
			actionText: "View published forms",
			icon: <FormsIcon />,
			url: "/forms",
			dashboardMode: ["integration"],
		},
	];

	const profile = JSON.parse(localStorage.getItem("pneumaCurrentUser") || "");
	const { first_name, last_name } = profile || {};

	return (
		<DashboardWrapper>
			<ContentWrapper>
				<Row gutter={0}>
					<Col span={24} className="overview-intro-pane">
						<OverviewIntroCardWrapper>
							<div className="circles" />
							<div className="inner">
								<Row align="middle">
									<Col xs={24} md={12}>
										<h3>
											Welcome to your dashboard, {first_name || last_name}!
										</h3>
										<p>
											Here, you can see all your users, encounters and wallet
											balance
										</p>
									</Col>
									<Col xs={24} md={12} style={{ textAlign: "right" }}>
										<Link to="/manage-account">
											<Button type="secondary">Manage Account</Button>
										</Link>
									</Col>
								</Row>
							</div>
						</OverviewIntroCardWrapper>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Row gutter={16} equal-heights={true}>
							{statsData1
								.filter((item) => {
									return item.dashboardMode.includes(dashboardMode);
								})
								.map(
									({ title, value, loading, actionText, url, icon }, index) => {
										return (
											<Col
												xs={12}
												md={7}
												key={index + url}
												style={{ height: "100%" }}
											>
												<StatsCardItem
													title={title}
													value={value}
													icon={icon}
													loading={loading}
													url={url}
													actionText={actionText}
												/>
											</Col>
										);
									},
								)}

							{statsData2
								.filter((item) => {
									return item.dashboardMode.includes(dashboardMode);
								})
								.map(
									({ title, value, loading, actionText, url, icon }, index) => {
										return (
											<Col
												xs={24}
												md={7}
												key={index + url}
												style={{ height: "100%" }}
											>
												<StatsCardItem
													title={title}
													value={value}
													loading={loading}
													icon={icon}
													url={url}
													actionText={actionText}
												/>
											</Col>
										);
									},
								)}
						</Row>
					</Col>
				</Row>
				<Row className="encounters-pane">
					{dashboardMode === "integration" ? (
						<>
							<Col xs={0} md={12}>
								<h2>Recent Encounters</h2>
							</Col>
							<Col xs={0} md={12} style={{ textAlign: "right" }}>
								<Link to="/encounters">See All</Link>
							</Col>
						</>
					) : (
						<>
							<Col xs={0} md={12}>
								<h2>Recent Requests</h2>
							</Col>
							<Col xs={0} md={12} style={{ textAlign: "right" }}>
								<Link to="/service-requests">See All</Link>
							</Col>
						</>
					)}
					<Col xs={0} md={24}>
						{dashboardMode === "integration" ? (
							<EncounterTable count={5} hasPagination={false} marginTop={10} />
						) : (
							<LabRequestsTable
								count={5}
								hasPagination={false}
								marginTop={10}
							/>
						)}
					</Col>
				</Row>
			</ContentWrapper>
		</DashboardWrapper>
	);
};

export default Dashboard;

const StatsCardItem = ({
	title,
	value,
	loading,
	actionText,
	url,
	icon,
}: any) => {
	return (
		<StatsCardWrapper active={false} isCurrency={title === "Wallet Balance"}>
			<Card>
				{loading ? (
					<Skeleton paragraph={{ rows: 1 }} />
				) : (
					<div className="content">
						<div className="flex">
							<div>
								<p className="title">{title}</p>
								<h2>
									{value.toLocaleString()}
									{title === "Reviews & Ratings" && <small>/5.0</small>}
								</h2>
							</div>
							<span className="icon">{icon}</span>
						</div>
						<span className="link">
							<Link to={url}>
								{actionText} <i className="lni lni-arrow-right" />
							</Link>
						</span>
					</div>
				)}
			</Card>
		</StatsCardWrapper>
	);
};
